<template>
  <v-container fluid>
    <base-loader :isLoading="isLoading"></base-loader>
    <base-dialog-notification ref="paymentHistoryConfirm" />
    <base-snackbar-notification ref="paymentHistorySnackbar" />
    <template v-if="users.records.length > 0">
      <v-row>
        <v-col cols="12" md="6">
          <!-----------------------Start of Employee Selector ---------------------------->
          <div class="name-selecter-parent">
            <div class="name-selecter" @click="show = !show">
              <v-btn color="black" text>
                {{
                  selectedItem
                    ? selectedItem.firstName + " " + selectedItem.lastName
                    : "All"
                }}
                <v-icon :class="' ico-point2'">{{
                  show ? "mdi-menu-up" : "mdi-menu-down"
                }}</v-icon>
              </v-btn>
            </div>
            <v-expand-transition>
              <div v-show="show" class="select-list">
                <v-list-item-group color="white">
                  <v-list-item
                    v-for="(item, i) in users.records"
                    :key="i"
                    @click="
                      selectedItem = item;
                      show = !show;
                      model = item.id;
                      fetchMore(item.id);
                    "
                  >
                    <v-list-item-icon>
                      <v-icon v-if="!selectedItem.profilePicture">mdi-account</v-icon>
                      <v-avatar v-else size="42">
                        <v-img :src="selectedItem.profilePicture"></v-img>
                      </v-avatar>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.firstName + ' ' + item.lastName"
                        class="text-subtitle-1"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </div>
            </v-expand-transition>
          </div>
          <!-----------------------End of Selector ---------------------------->

          <!-----------------------Start of Employee Payment lines ------------->
          <template v-if="paymentsInitialized">
              <v-col cols="12" md="6" :v-for="paymentsData.length > 0" width="100vw" class="px-0 py-0">
                <v-list two-line>
                  <v-list-item-group>
                    <template v-for="(item, index) in paymentsData">
                      <v-list-item
                          :key="item.id"
                          :value="item"
                          @click="showPaymentDetailDialog(item)"
                      >
                        <template v-slot:default="{ active }">
                          <v-list-item-avatar>
                            <v-icon
                                v-bind:class="returnListPaymentIconColour(item.employerPaymentStatus)"
                                v-text="returnListPaymentIcon(item.employerPaymentStatus)"
                            >
                            </v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                                class="text-body-1 black--text font-weight-bold"
                                v-bind:class="returnListPaymentColour(item.employerPaymentStatus)"
                                v-text="returnListPaymentAmount(item.employerPaymentAmount, item.currencyCode, item.employerPaymentStatus)"
                            ></v-list-item-title>
                            <v-list-item-subtitle class="dark-grey--text" v-text="formatDate(item.employerPaymentDate)"></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-action-text>
                              <v-icon
                                class="d-flex justify-end secondary--text"
                              >mdi-chevron-right-circle-outline</v-icon>
                            </v-list-item-action-text>
                          </v-list-item-action>
                        </template>
                      </v-list-item>

                      <v-divider
                          v-if="index < paymentsData.length - 1"
                          :key="index"
                      ></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-col>
          </template>
          <template v-else>
            <v-row>
              <v-col cols="1"></v-col>
              <v-col cols="10"><p>You have not made any payments to this employee yet.</p></v-col>
              <v-col cols="1"></v-col>
            </v-row>
          </template>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="absolute-center"
          v-if="
            $vuetify.breakpoint.md ||
            $vuetify.breakpoint.lg ||
            $vuetify.breakpoint.xl
          "
        >
          <v-avatar class="" size="400" tile>
            <v-img src="../../assets/FairwageLogo.png"></v-img>
          </v-avatar>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row class="mt-4">
        <v-col cols="12" class="d-flex mx-auto">
          <v-alert
              dense
              outlined
              type="warning"
          >You don't have any employees setup. Please add an employee first.</v-alert>
        </v-col>
      </v-row>
    </template>

    <v-dialog
        v-model="showPaymentDetail"
        persistent
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn
              icon
              dark
              @click="showPaymentDetail = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="white--text">Payment Details</v-toolbar-title>
        </v-toolbar>
        <template v-if="showPaymentDetail">
          <v-card-text class="px-2">
            <v-container>
              <v-row>
                <v-col cols="12 pb-0 d-flex justify-end" >
                  <span
                      v-text="$moment(selectedPayment.createdAt).format('HH:mm A, dddd, DD MMM YYYY')"
                      class="text-content"
                  ></span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"><span class="text-h5">Period: {{$moment(selectedPayment.workDaysStartDate).format('DD MMM')}} - {{$moment(selectedPayment.workDaysEndDate).format('DD MMM')}}</span></v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row>
                <v-col cols="2"><v-spacer></v-spacer></v-col>
                <v-col cols="10" class="text-right"><span class="text-h5 font-weight-black mr-2">TOTAL WORK PAY:</span><span class="mr-2 text-h5 font-weight-black">{{ returnCurrencyFormattedNumber(selectedPayment.totalWorkPay, selectedPayment.currencyCode) }}</span></v-col>
              </v-row>
              <template>
                <v-row>
                  <v-col cols="4"><span class="text-h5">Deductions</span></v-col>
                </v-row>
                <v-divider class="my-2"></v-divider>
                <v-row v-if="selectedPayment.deductions.length > 0" class="mb-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-list color="white" dense class="mt-2">
                      <v-list-item v-for="(deduction, index) in selectedPayment.deductions" :key="index">
                        <v-list-item-icon class="mr-3" v-if="!deduction.new">
                          <v-icon color="warning">mdi-cash-remove</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="text-capitalize font-weight-bold mr-3" v-text="deduction.description"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action-text>
                          <v-list-item-title class="font-weight-black">{{ returnCurrencyFormattedNumber(deduction.amount, selectedPayment.currencyCode) }}</v-list-item-title>
                        </v-list-item-action-text>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12"><span class="text-body-2">(None)</span></v-col>
                </v-row>
                <template v-if="selectedPayment.deductions.length > 0">
                  <v-divider class="my-2"></v-divider>
                  <v-row>
                    <v-col cols="6"><v-spacer></v-spacer></v-col>
                    <v-col cols="6" class="text-right"><span class="text-h5 font-weight-black mr-2">TOTAL:</span><span class="mr-2 text-h5 font-weight-black">- {{ totalFormattedDeductions(selectedPayment.deductions, selectedPayment.currencyCode) }}</span></v-col>
                  </v-row>
                </template>
                <template v-else>
                  <v-row  class="my-4">
                  </v-row>
                </template>
                <template>
                  <v-row class="mt-6">
                    <v-col cols="6" class="py-0 my-0"><span class="text-body-1 font-weight-medium">{{serviceFeeWording(selectedPayment.serviceFeeAmount, selectedPayment.serviceFeeType)}}</span></v-col>
                    <v-col cols="2" class="py-0 my-0"><v-spacer></v-spacer></v-col>
                    <v-col cols="4" class="py-0 my-0 text-right"><span class="mr-2 text-body-1 font-weight-medium">+ {{ totalServiceFee(selectedPayment.employeePaymentAmount, totalDeductions(selectedPayment.deductions), selectedPayment.serviceFeeAmount, selectedPayment.serviceFeeType, selectedPayment.currencyCode) }}</span></v-col>
                  </v-row>
                </template>
                <template>
                  <v-row class="mt-1">
                    <v-col cols="6"><span class="text-h5 font-weight-black">TOTAL PAYMENT</span></v-col>
                    <v-col cols="6" class="text-right"><span class="mr-2 text-h5 font-weight-black">{{ returnCurrencyFormattedNumber(selectedPayment.employerPaymentAmount, selectedPayment.currencyCode) }}</span></v-col>
                  </v-row>
                  <v-divider class="my-2"></v-divider>
                </template>
                <template>
                  <v-row class="mt-1">
                    <v-col cols="6"><span class="text-h5">Paid To:</span></v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="5"><span class="text-body-2 font-weight-black">Name:</span></v-col>
                    <v-col cols="7"><span class="text-body-2">{{ selectedPayment.employeeAccountHolder }}</span></v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="5"><span class="text-body-2 font-weight-black">Acc. Number:</span></v-col>
                    <v-col cols="7"><span class="text-body-2">{{ selectedPayment.employeeAccountNumber }}</span></v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="5"><span class="text-body-2 font-weight-black">Bank:</span></v-col>
                    <v-col cols="7"><span class="text-body-2">{{ selectedPayment.employeeBankName }}</span></v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="5"><span class="text-body-2 font-weight-black">Branch Code:</span></v-col>
                    <v-col cols="7"><span class="text-body-2">{{ selectedPayment.employeeBranchCode }}</span></v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="5"><span class="text-body-2 font-weight-black">Amount:</span></v-col>
                    <v-col cols="7"><span class="text-body-2">{{ returnCurrencyFormattedNumber(selectedPayment.employeePaymentAmount, selectedPayment.currencyCode) }}</span></v-col>
                  </v-row>
                  <v-row class="">
                  </v-row>
                  <v-divider class="my-4"></v-divider>
                </template>
                <template>
                  <v-row class="mt-0">
                    <v-col cols="6" class="pt-0"><span class="text-h5">Transaction Details:</span></v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="5"><span class="text-body-2 font-weight-black">Status:</span></v-col>
                    <v-col cols="7"><span class="text-body-2">{{ formatPaymentStatus(selectedPayment.employerPaymentStatus) }}</span></v-col>
                  </v-row>
                  <v-row class="mt-0" v-if="!selectedPayment.employerPaymentAccepted">
                    <v-col cols="5"><span class="text-body-2 font-weight-black">Reason:</span></v-col>
                    <v-col cols="7"><span class="text-body-2">{{ selectedPayment.employerPaymentFailureReason }}</span></v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="5"><span class="text-body-2 font-weight-black">Method:</span></v-col>
                    <v-col cols="7"><span class="text-body-2">{{ selectedPayment.employerPaymentMethod }}</span></v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="5"><span class="text-body-2 font-weight-black">Reference:</span></v-col>
                    <v-col cols="7"><span class="text-body-2">{{ selectedPayment.employerPaymentReference }}</span></v-col>
                  </v-row>

                </template>
              </template>
            </v-container>
          </v-card-text>
        </template>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { gql } from "apollo-boost";
import moment from 'moment/moment'

export default {
  components: {},
  name: "Payment History",
  mounted() {
    this.$emit("update:pageName", this.$options.name);
  },
  data() {
    return {
      isLoading: false,
      show: false,
      showMore: false,
      showPaymentDetail: false,
      selectedItem: 1,
      selectedPayment: {},
      paymentsInitialized: false,
      routeEmployeeId: null,
      routePaymentId: null,
      paymentId: null,
      userDetails: {},
      model: "tab-1",
      users: [],
      payments: [],
      paymentsData: [],
      page: 1,
      id: "",
      UserId: "",
      tab: null,
      newItems: [],

    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
          firstName
          lastName
        }
      }
    `,
    users: {
      query: gql`
        query Users($limit: Int, $page: Int, $query: JSON!) {
          users(limit: $limit, page: $page, query: $query) {
            records {
              id
              firstName
              lastName
              role
            }
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 9999;
        return {
          limit: limit,
          page: this.page,
          query: { employers: { $in: [ this.me.id ] }, role: "employee", deleted: { $ne: true } },
        };
      },
      result({ data }, key) {
        this.newItems = this.$_.get(data, 'users.records', [])
        if (this.newItems.length > 0) {
          this.selectedItem = this.newItems[0];
          this.userId = this.newItems[0].id;
          this.userDetails = this.newItems[0];
        }
        if (this.routeEmployeeId !== null) {
          const employee = this.newItems.find(item => item.id === this.routeEmployeeId)
          this.userId = this.routeEmployeeId
          this.userDetails = employee
          this.selectedItem = employee
          //this.getDataForWorkTable(this.userId, null, this.userDetails.lastPaymentDate)
          console.log('--------- Route employee ID: ----------', this.routeEmployeeId)
        } else if (this.userId !== "") {
          if (this.routePaymentId === 'all') {
            console.log('--------- Route payment ID ALL:  ----------', this.routePaymentId)
            //this.getDataForWorkTable(this.userId, null, this.userDetails.lastPaymentDate)
          } else {
            console.log('--------- Route payment ID with User ID: ----------', this.routePaymentId)
            this.fetchMore(this.userId)
          }
        }

      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },
  methods: {
    async fetchMore(userId) {
      const query = gql`
        query Payments($limit: Int!, $skip: Int!, $query: JSON!) {
          payments(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              employerId
              employerFirstName
              employerLastName
              employerPaymentDate
              employerPaymentAmount
              employerPaymentReference
              employerPaymentStatus
              employerPaymentAccepted
              employerPaymentFailureReason
              employerPaymentRequestTrace
              employerPaymentMethod
              employerPaymentCcToken
              employerPaymentCcHolder
              employerPaymentCcMasked
              employerPaymentCcExpiry
              employeeId
              employeeFirstName
              employeeLastName
              employeeAccountNumber
              employeeAccountType
              employeeAccountHolder
              employeeBankName
              employeeBranchCode
              employeePaymentDate
              employeePaymentAmount
              totalWorkPay
              wageRate
              wagePeriod
              wageTracking
              workDaysStartDate
              workDaysEndDate
              currencyCode
              deductions {
                  id
                  description
                  amount
                }
              serviceFeeAmount
              serviceFeeType
              createdAt
              createdBy
              updatedAt
              updatedBy
              deleted
              deletedAt
              deletedBy
            }
          }
        }
      `;
      await this.$apollo.query({
        query: query,
        fetchPolicy: "no-cache",
        variables: {
          limit: 99999,
          skip: 0,
          query: {
            deleted: { $ne: true },
            employeeId: userId,
          },
        },
      }).then((data) => {
        this.paymentsInitialized = false
        this.paymentsData = this.$_.get(data, 'data.payments.records', [])
        if (this.$_.size(this.paymentsData) > 0) {
          this.paymentsInitialized = true
        }
        return this.payments
      }).catch((error) => {
        console.error('Error:', error)
      })
    },
    formatDate(date) {
      return this.$moment(date).format('dddd, DD MMMM YYYY')
    },
    returnCurrencyFormattedNumber (number, currency) {
      if (number == null || number === 'undefined') return 'R 0.00'
      const roundedNumber = this.$_.round(number, 2)
      if (!currency) return `R ${roundedNumber}`
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'narrowSymbol'
      })
      return formatter.format(roundedNumber)
    },
    returnListPaymentAmount (number, currency, status) {
      const formattedNumber = this.returnCurrencyFormattedNumber(number, currency)
      if (status === 'PENDING') return `${formattedNumber} (Pending)`
      if (status === 'PAID') return `${formattedNumber}`
      if (status === 'DECLINED') return `${formattedNumber} (Declined)`
      if (status === 'FAILED') return `${formattedNumber} (Failed)`
      return `${formattedNumber} (Unknown)`
    },
    returnListPaymentColour (status) {
      if (status === 'PENDING') return 'black--text'
      if (status === 'PAID') return 'black--text'
      if (status === 'DECLINED') return 'orange--text'
      if (status === 'FAILED') return 'orange--text'
      return 'black--text'
    },
    returnListPaymentIcon (status) {
      console.log('Payment Amount Status: ', status)
      if (status === 'PENDING') return 'mdi-progress-clock'
      if (status === 'PAID') return 'mdi-currency-usd'
      if (status === 'DECLINED') return 'mdi-currency-usd-off'
      if (status === 'FAILED') return 'mdi-currency-usd-off'
      return 'mdi-progress-clock'
    },
    returnListPaymentIconColour (status) {
      if (status === 'PENDING') return 'black--text'
      if (status === 'PAID') return 'secondary--text'
      if (status === 'DECLINED') return 'orange--text'
      if (status === 'FAILED') return 'orange--text'
      return 'black--text'
    },
    showPaymentDetailDialog(item) {
      if (!this.$_.isUndefined(item)) {
        this.selectedPayment = item
      }
      this.showPaymentDetail = true
    },
    formatPaymentStatus(status) {
      if (status) return this.$_.capitalize(status)
      return this.$_.capitalize('Unknown')
    },
    totalDeductions (deductions) {
      let totalDeductionsAmount = 0
      if(this.$_.size(deductions) > 0) {
        this.$_.forEach(deductions, (deduction) => {
          totalDeductionsAmount += this.$_.toNumber(deduction.amount)
        })
      }
      return this.$_.round(totalDeductionsAmount, 2)
    },
    totalFormattedDeductions (deductions, currency) {
      let totalDeductionsAmount = 0
      if(this.$_.size(deductions) > 0) {
        this.$_.forEach(deductions, (deduction) => {
          totalDeductionsAmount += this.$_.toNumber(deduction.amount)
        })
      }
      const formattedNumber = this.returnCurrencyFormattedNumber(totalDeductionsAmount, currency)
      return formattedNumber
    },
    totalServiceFee (totalWork, totalDeductions, serviceFee, serviceFeeType, currency) {
      if(!totalDeductions) totalDeductions = 0
      if (serviceFeeType === 'percentage') {
        const totalPay = totalWork - totalDeductions
        const calcServiceFee = totalPay * (serviceFee / 100)
        const roundedTotal = this.returnCurrencyFormattedNumber(calcServiceFee, currency)
        return roundedTotal
      } else {
        return this.returnCurrencyFormattedNumber(serviceFee, currency)
      }
    },
    serviceFeeWording (serviceFee, serviceFeeType) {
      if (serviceFeeType === 'percentage') {
        return `Service Fee (${serviceFee}%)`
      } else {
        return 'Service Fee'
      }
    },
  },
  computed: {

  },
};
</script>

<style scoped>
.herso {
  background: url("../../assets/landing.png") no-repeat center center fixed !important;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.card-header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
}

.select-card {
  padding: 10px 30px;
  min-height: 60px;
  position: relative;
}

.top-sect .v-btn {
  width: calc(100% - 67px);
  min-height: 70px;
}

.top-sect .v-btn__content {
  color: #eeecf1 !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  letter-spacing: 0.75px;
}

.top-sect .v-card__actions {
  position: absolute;
  right: 10px;
  top: 10px;
}

.top-sect .v-icon.v-icon {
  color: #ffffff;
}

.select-list {
  margin-top: 10px;
}

.ico-point {
  font-size: 30px;
}

.app-btn {
  height: auto;
  min-height: 10px;
  background-color: #fff;
  min-width: 0;
  border-radius: 12px;
  padding: 0;
}

.app-btn .v-btn__content {
  display: block !important;
  padding: 10px;
}

.v-application .secondary.app-btn {
  background-color: #f1f3f6 !important;
  border-color: #f1f3f6 !important;
  color: #3f3d56;
  box-shadow: none;
}
.v-application .primary.app-btn {
  background-color: #6dd984 !important;
  border-color: #6dd984 !important;
  color: #fff;
}

.app-btn .v-btn__content i {
  margin-bottom: 10px;
  border: 2px solid;
  border-radius: 50%;
}

.theCaleSect,
.btn-group {
  text-align: center;
}

/*/////////////////////////*/
.forCale {
  background-color: white;
  padding: 10px !important;
  overflow: hidden;
}

.forCale .names {
  background: #a5a6f6;
  border-radius: 10px;
}

.v-btn:not(.v-btn--round).v-size--default.app-btn {
  height: auto;

  min-width: auto;
  padding: 10px 10px !important;
}

/*/////////////////////////*/

.name-selecter-parent {
  border: 4px solid#6dd984;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #6dd984;
}

.name-selecter > button {
  width: 100%;
  position: relative;
}

.name-selecter > button i {
  position: absolute;
  right: 0;
}

/*history*/
.app-tabs-rows {
  padding: 0 30px;
}
.app-tabs-rows h1 {
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;

  color: #7d72f5;
}
.app-tabs-rows .col {
  padding: 20px 20px;
  margin-top: 10px;
  height: auto;
}
.app-tabs-rows p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  color: #3f3d56;
}

.app-tabs-rows b {
  font-weight: 700;
}
</style>
